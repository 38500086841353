import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { DropdownMenu, DropdownTrigger, FlagIcon } from '@junglescout/edna'

import { MARKETPLACES_IN_TRANSITION } from 'constants/countries'

const Option = styled.div`
  cursor: pointer;
  min-height: 35px;
  padding: 8px;
  &:hover {
    background-color: ${props => props.theme.colors.grey025};
  }
`
const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  [class*='FlagIcon'] {
    margin-right: 8px;
  }
`

const MarketplaceInput = ({ values, setValues }) => {
  const [open, setOpen] = useState(false)

  const value = values.country
  const setValue = country => {
    setOpen(false)
    setValues({ ...values, country })
  }

  const renderCountry = code => {
    const country = MARKETPLACES_IN_TRANSITION[code]
    return (
      <Item>
        <FlagIcon country={code} />
        {country.name}
      </Item>
    )
  }

  return (
    <DropdownMenu
      open={open}
      onOpenChange={setOpen}
      trigger={
        <DropdownTrigger width="200px">
          {value ? renderCountry(value) : 'Select an option'}
        </DropdownTrigger>
      }
      width="200px">
      {Object.keys(MARKETPLACES_IN_TRANSITION).map(code => (
        <Option key={code} onClick={() => setValue(code)}>
          {renderCountry(code)}
        </Option>
      ))}
    </DropdownMenu>
  )
}

MarketplaceInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired
}

export { MarketplaceInput }
