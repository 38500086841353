import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { isMobile } from 'react-device-detect'

import { createDateRanges } from 'helpers/calendar'
import { CalendarSelect } from 'ui_elements/CalendarSelect/CalendarSelect'

const Container = styled.div`
  [class*='TitleDropdown'] {
    width: ${isMobile ? 'auto' : '360px'};
    margin-left: 0 !important;
    width: ${isMobile ? 'unset' : '360px'};
  }
`

const minDate = moment().subtract(2, 'year')
const maxDate = moment()
const dateRanges = createDateRanges(maxDate)

const parseDate = date =>
  date
    ? moment(date)
        .utc()
        .startOf('day')
    : undefined

const DateRangeInput = ({ values, setValues, isComparison }) => {
  const startDateKey = isComparison ? 'comparison_start_date' : 'start_date'
  const endDateKey = isComparison ? 'comparison_end_date' : 'end_date'

  const [startDate, setStartDate] = useState(parseDate(values?.[startDateKey]))
  const [endDate, setEndDate] = useState(parseDate(values?.[endDateKey]))
  const [dateRangeType, setDateRangeType] = useState('Custom')

  const onDatesChange = dates => {
    const newStartDate = dates?.startDate
    const newEndDate = dates?.endDate
    const newDateRange = dates?.dateRange
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    setDateRangeType(newDateRange)
    setValues({
      ...values,
      [startDateKey]: newStartDate?.format('YYYY-MM-DD'),
      [endDateKey]: newEndDate?.format('YYYY-MM-DD'),
      dateRangeType: newDateRange
    })
  }

  return (
    <Container>
      <CalendarSelect
        startDate={startDate}
        endDate={endDate}
        dateRanges={dateRanges}
        minDate={minDate}
        maxDate={maxDate}
        initialVisibleMonth={() => minDate}
        onDatesChange={onDatesChange}
        onDateRangeChange={setDateRangeType}
        dateRangeType={dateRangeType}
        singleTitleHeader
      />
    </Container>
  )
}

DateRangeInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  isComparison: PropTypes.bool,
  setValues: PropTypes.func.isRequired
}

DateRangeInput.defaultProps = {
  isComparison: false
}

export { DateRangeInput }
